<template>
  <div class="art-piece">
    <div
      class="art-piece__background"
      :style="{
        backgroundImage: `url(${artwork.header_image})`,
      }"
    ></div>
    <div class="art-piece__content">
      <div class="art-piece__details-box">
        <div class="art-piece__details-box_tags">
          <Tag v-if="artwork.sku" class="tag--text tag--public" :value="`SKU: ${artwork.sku}`" />
        </div>
        <h2 class="art-piece__details-box_title">{{ artwork.title }}</h2>
        <router-link
          v-if="artwork.artist"
          class="art-piece__details-box_artist"
          :to="`/artists/${artwork.artist.slug}`"
        >
          {{ artwork.artist.first_name }} {{ artwork.artist.last_name }}
        </router-link>
        <div
          class="art-piece__description collect-editor collect-editor--white"
          v-html="artwork.description"
        ></div>
        <template v-if="artwork.size.length > 1">
          <span v-if="artwork.years" class="art-piece__details-box_details">
            {{ artwork.years }}
          </span>
          <span
            v-for="(size, index) in artwork.size"
            :key="`size-${index}`"
            class="art-piece__details-box_details"
          >
            {{ size }}
          </span>
        </template>
        <span v-else class="art-piece__details-box_details">
          {{ getArtworkProperties }}
        </span>
        <span v-if="artwork.availability === 0" class="art-piece__details-box_price">
          ${{ artwork.price }}
        </span>
        <span v-if="artwork.availability === 1" class="art-piece__details-box_price">
          Sold
        </span>
        <span v-if="artwork.availability === 2" class="art-piece__details-box_price">
          On Hold
        </span>
        <div class="art-piece__details-box_buttons">
          <BaseButton
            v-if="artwork.availability === 0"
            class="button button--xs button--white"
            text="INQUIRE"
            @click="clickInquire"
          />
          <BaseButton
            v-if="artwork.availability === 0"
            class="button button--xs button--outline-white"
            text="VIRTUAL PLACEMENT"
            @click="
              $router.push({
                name: 'ShopItemVirtualPlacement',
                params: { name: artwork.title },
              })
            "
          />
        </div>
      </div>

      <div v-if="isCarouselVisible" class="carousel-wrapper">
        <IconButton
          class="carousel__button carousel__button--back button--circle button--white"
          @click="goToSlide('prev')"
        >
          <IconArrowTop class="carousel__button-icon carousel__button-icon" />
        </IconButton>
        <VueSlickCarousel v-bind="carouselSettings" ref="carousel">
          <div v-for="(item, key) in artwork.product_images" :key="`image-${key}`" class="slide">
            <img class="image" :src="item" :alt="artwork.title" />
          </div>
        </VueSlickCarousel>
        <IconButton
          class="carousel__button carousel__button--next button--circle button--white"
          @click="goToSlide('next')"
        >
          <IconArrowTop class="carousel__button-icon carousel__button-icon" />
        </IconButton>
      </div>

      <img v-else class="artwork-image" :src="artwork.product_images" :alt="artwork.title" />

      <div v-if="randomArtByArtist.length > 0" class="art-piece__title-box">
        <h2 class="art-piece__title-box_title">
          More by {{ artwork.artist.first_name }} {{ artwork.artist.last_name }}
        </h2>
      </div>

      <div v-if="randomArtByArtist.length > 0" class="art-piece__artworks-box">
        <router-link
          v-for="(art, key) in randomArtByArtist"
          :key="`art-${key}`"
          :to="{ path: `/artworks/${art.slug}` }"
          v-slot="{ navigate }"
          custom
        >
          <div class="art-piece__artworks-box_card" @click="navigate">
            <div class="image-box">
              <img class="image" :src="art.search_image" :alt="art.title" />
              <!-- <div v-if="art.sku" class="code">{{ art.sku }}</div> -->
              <div class="hover-container">Click to View</div>
            </div>
            <h3 class="title">{{ art.title }}</h3>
            <!-- <span class="price">${{ art.price }}</span> -->
            <span class="author">{{ art.artist.first_name }} {{ art.artist.last_name }}</span>
          </div>
        </router-link>
      </div>
      <div v-if="randomArtByArtist.length > 0">
        <div class="art-piece__footer">
          <BaseButton
            class="button--xs button--white"
            text="VIEW MORE BY ARIST"
            @click="$router.push({ path: `/artists/${artwork.artist.slug}` })"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import Tag from "@/components/Tag.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import IconArrowTop from "@/components/icons/IconArrowTop.vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

export default {
  components: { Tag, BaseButton, IconButton, IconArrowTop, VueSlickCarousel },

  data() {
    return {
      carouselSettings: {
        arrows: false,
        draggable: false,
        infinite: true,
        dots: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    };
  },
  head: {
    title: function () {
      return {
        inner: this.artwork.title ?? "Artwork browngrotta arts",
      };
    },
    meta: function () {
      return [
        {
          name: "description",
          itemprop: "description",
          content: `${this.artwork.title ?? "Artwork browngrotta arts"} - ${
            this.artwork.description ?? "Description Artwork browngrotta arts"
          }`,
          id: "meta:description",
        },
        {
          itemprop: "name",
          content: this.artwork.title ?? "Artwork browngrotta arts",
          id: "meta:name",
        },
        {
          itemprop: "image",
          content:
            this.artwork.header_image ??
            `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "meta:image",
        },
        {
          property: "og:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "og:url",
        },
        {
          property: "og:type",
          content: "website",
          id: "og:type",
        },
        {
          property: "og:title",
          content: this.artwork.title ?? "Artwork browngrotta arts",
          id: "og:title",
        },
        {
          property: "og:image",
          content:
            this.artwork.header_image ??
            `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "og:image",
        },
        {
          property: "og:image:alt",
          content: this.artwork.title ?? "Artwork browngrotta arts",
          id: "og:image:alt",
        },
        {
          property: "og:description",
          content: this.artwork.description ?? "Description Artwork browngrotta arts",
          id: "og:description",
        },
        {
          property: "og:site_name",
          content: process.env.VUE_APP_FRONTEND_URL,
          id: "og:site_name",
        },
        {
          property: "og:locale",
          content: "en_US",
          id: "og:locale",
        },
        {
          name: "twitter:card",
          content: "summary",
          id: "twitter:card",
        },
        {
          name: "twitter:site",
          content: "@BrowngrottaArts",
          id: "twitter:site",
        },
        {
          name: "twitter:creator",
          content: "@BrowngrottaArts",
          id: "twitter:creator",
        },
        {
          name: "twitter:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "twitter:url",
        },
        {
          name: "twitter:title",
          content: this.artwork.title ?? "Artwork browngrotta arts",
          id: "twitter:title",
        },
        {
          name: "twitter:description",
          content: this.artwork.description ?? "Description Artwork browngrotta arts",
          id: "twitter:description",
        },
        {
          name: "twitter:image",
          content:
            this.artwork.header_image ??
            `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "twitter:image",
        },
        {
          name: "twitter:image:alt",
          content: this.artwork.title ?? "Artwork browngrotta arts",
          id: "twitter:image:alt",
        },
      ];
    },
  },

  async created() {
    this.resetArtwork();
    await this.apiGetArtPiece(this.$route.params.slug);
    this.$emit("updateHead");

    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Artworks",
        to: "/artworks",
        clickable: true,
      },
      {
        title: this.artwork.title,
        clickable: false,
      },
    ];
    this.setBreadcrumbs(breadcrumbs);

    this.setShareData({
      title: this.artwork.title,
      description: this.artwork.description,
      image: this.artwork.header_image,
    });
  },

  watch: {
    "$route.path": async function () {
      await this.apiGetArtPiece(this.$route.params.slug);
      if (this.isCarouselVisible) {
        await this.$refs.carousel.goTo(0, true);
      }
      this.$emit("updateHead");

      const breadcrumbs = [
        {
          title: "Home",
          to: "/",
        },
        {
          title: "Artworks",
          to: "/artworks",
          clickable: true,
        },
        {
          title: this.artwork.title,
          clickable: false,
        },
      ];
      this.setBreadcrumbs(breadcrumbs);

      this.setShareData({
        title: this.artwork.title,
        description: this.artwork.description,
        image: this.artwork.header_image,
      });
    },
  },

  computed: {
    ...mapGetters("publicapi/shop", {
      artwork: "getArtPiece",
      randomArtByArtist: "getRandomArtByArtist",
    }),

    isCarouselVisible() {
      return this.artwork?.product_images?.length > 1 ? true : false;
    },

    // getFirstImage() {
    //   let image = this.artwork?.product_images[0];
    //   return image ? image : { image: "", alt: "" };
    // },

    getArtworkProperties() {
      if (this.artwork?.size[0] && this.artwork?.years) {
        return `${this.artwork?.size[0]}, ${this.artwork?.years}`;
      }
      if (this.artwork?.size[0]) {
        return `${this.artwork?.size[0]}`;
      }
      if (this.artwork?.years) {
        return `${this.artwork?.years}`;
      }
      return "";
    },
  },

  methods: {
    ...mapActions("publicapi/shop", ["apiGetArtPiece"]),
    ...mapMutations("publicapi/share", ["setShareData"]),
    ...mapMutations(["setBreadcrumbs"]),
    ...mapMutations("publicapi/shop", ["resetArtwork"]),

    goToSlide(moveDirection) {
      let carousel = this.$refs.carousel;

      switch (moveDirection) {
        case "prev": {
          carousel.prev();
          break;
        }
        case "next": {
          carousel.next();
          break;
        }
      }
    },
    clickInquire() {
      if (this.artwork.shop_url) {
        window.open(this.artwork.shop_url, "_blank");
      } else {
        this.$router.push({
          name: "ShopItemInquire",
          params: { name: this.artwork.title },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.art-piece {
  display: flex;
  max-width: 100%;
  width: 100vw;
  min-height: 100vh;
  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 58.4rem;
    z-index: 0;
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    @media screen and (max-width: $xs) {
      height: 33.5rem;
    }
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba($color: $lightBlack, $alpha: 0.45);
      z-index: 1;
    }
    &::after {
      content: "";
      bottom: -1px;
      position: absolute;
      width: 100%;
      height: 50%;
      background: linear-gradient(
        0deg,
        rgba($color: $lightBlack, $alpha: 1) 0%,
        rgba($color: $lightBlack, $alpha: 0) 100%
      );
      z-index: 1;
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    z-index: 2;
    padding: 20rem 10.5rem;
    @media screen and (max-width: $xs) {
      padding: 24.5rem 2rem 11rem;
    }
  }

  &__details-box {
    display: flex;
    flex-direction: column;
    width: 55%;
    @media screen and (max-width: $md) {
      width: 100%;
    }

    &_tags {
      display: flex;
      margin: 0 0 15px -10px;

      .tag--public {
        max-width: 95%;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &_title {
      color: $white;
      font-size: 4rem;
      line-height: 5rem;
      @media screen and (max-width: $xs) {
        font-size: 3.5rem;
        line-height: 4.3rem;
        margin-bottom: 1rem;
      }
    }

    &_artist {
      color: $white;
      font-size: 2.4rem;
      line-height: 5rem;
      margin-bottom: 1.2rem;
      text-decoration: none;
      @media screen and (max-width: $xs) {
        font-size: 2rem;
        line-height: 2.5rem;
        margin-bottom: 1.7rem;
      }
    }

    &_details {
      font-family: $fontFrutiger;
      font-weight: 100;
      margin-bottom: 0.5rem;
      color: $white;
      font-size: 18px;
      line-height: 1.33;
      @media screen and (max-width: $xs) {
        font-size: 16px;
        line-height: 1.56;
      }
    }

    &_price {
      line-height: 2.8rem;
      color: $white;
      font-size: 18px;
      @media screen and (max-width: $xs) {
        font-size: 16px;
        line-height: 1.56;
      }
    }

    &_buttons {
      display: flex;
      margin-top: 3rem;

      .button {
        min-width: 180px;
        margin-right: 15px;
        @media screen and (max-width: $xs) {
          min-width: 160px;
        }
        @media screen and (max-width: $xxxxs) {
          min-width: 130px;
        }
      }
    }
  }

  .carousel {
    &-wrapper {
      position: relative;
      margin: 12rem 0rem 12.5rem;
      width: 80%;
      @media screen and (max-width: $lg) {
        width: 100%;
      }
      @media screen and (max-width: $xs) {
        margin: 7.5rem 0rem 14.3rem;
      }

      .slide {
        display: flex !important;
        justify-content: center;
        padding: 0px 5px;
        @media screen and (max-width: $xs) {
          padding: 0rem;
        }

        .image {
          height: 800px;
          width: 800px;
          object-fit: cover;
          background-size: cover;
          background-position: center;
          @media screen and (max-width: $xs) {
            height: 344px;
          }
        }
      }
    }

    &__button {
      width: 7rem;
      height: 7rem;
      @media screen and (max-width: $xs) {
        width: 4.2rem;
        height: 4.2rem;
      }
      &--next {
        position: absolute;
        right: 10.5rem;
        top: 50%;
        transform: rotate(90deg) translateY(-50%);
        z-index: 1;
        @media screen and (max-width: $xs) {
          right: 4rem;
        }
      }
      &--back {
        position: absolute;
        left: 10.5rem;
        top: 50%;
        transform: rotate(-90deg) translateY(-50%);
        z-index: 1;
        @media screen and (max-width: $xs) {
          left: 4rem;
        }
      }
      &-icon {
        width: 18px;
        height: 12px;
        @media screen and (max-width: $xs) {
          width: 10px;
          height: 6px;
        }
      }
    }
  }

  .artwork-image {
    height: 800px;
    width: 800px;
    object-fit: cover;
    background-size: cover;
    background-position: center;
    margin: 12rem 0rem 12.5rem;
    @media screen and (max-width: $xs) {
      height: 344px;
      margin: 7.5rem 0rem 14.3rem;
    }
  }

  &__title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 5rem;
    border-top: 1px solid rgba(255, 255, 255, 0.6);
    @media screen and (max-width: $xs) {
      border-top: none;
    }
    &_right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 5rem;
      @media screen and (max-width: $md) {
        width: 100%;
        flex-direction: column;
        .divider {
          display: none;
        }
        &-content {
          &:last-of-type {
            margin-left: 10px;
          }
        }
      }
      @media screen and (max-width: $xs) {
        display: none;
      }
      &-content {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        & > * {
          margin-bottom: 10px;
        }

        .button--outline-white {
          text-transform: uppercase;
          margin-bottom: 0px;
        }
      }
      @media screen and (max-width: $lg) {
        margin-top: 30px;
        margin-left: -10px;
      }
      @media screen and (max-width: $xs) {
        &-content {
          flex-wrap: wrap;
        }
      }
    }

    &_title {
      font-size: 4rem;
      line-height: 5rem;
      margin-top: 5rem;
      color: $white;
      @media screen and (max-width: $xs) {
        font-size: 3.5rem;
        line-height: 4.3rem;
        margin-top: 0rem;
      }
    }
  }

  &__artworks-box {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(30%, 1fr));
    grid-column-gap: 3rem;
    grid-row-gap: 7rem;
    @media screen and (max-width: $md) {
      grid-template-columns: repeat(2, minmax(30%, 1fr));
    }
    @media screen and (max-width: $xs) {
      grid-template-columns: minmax(30%, 1fr);
      grid-row-gap: 7rem;
    }

    &_card {
      display: flex;
      flex-direction: column;
      cursor: pointer;

      &:hover {
        .image-box {
          .hover-container {
            opacity: 1;
          }
        }
      }

      .image-box {
        position: relative;

        .image {
          height: 27vw;
          width: 100%;
          object-fit: cover;
          background-size: cover;
          background-position: center;
          border-radius: 4px;
          @media screen and (max-width: $xs) {
            height: 90vw;
          }
        }

        .code {
          position: absolute;
          right: 9px;
          bottom: 9px;
          display: flex;
          justify-content: center;
          align-content: center;
          text-transform: uppercase;
          border: 1px solid $white;
          border-radius: 5px;
          background-color: rgba(0, 0, 0, 0.45);
          max-width: 30%;
          min-width: 7.2rem;
          font-size: 1.4rem;
          line-height: 2.5rem;
          padding: 0.1rem 1rem;
          z-index: 2;
          display: -webkit-box;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: $white;
          @media screen and (max-width: $xs) {
            font-size: 16px;
            line-height: 1.56;
          }
        }

        .hover-container {
          position: absolute;
          width: 100%;
          height: 100%;
          font-size: 3rem;
          line-height: 5rem;
          border-radius: 4px;
          background-color: rgba($color: $blue, $alpha: 0.83);
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.3s ease;
          opacity: 0;
          top: 0;
          color: $white;
          @media screen and (max-width: $xs) {
            font-size: 16px;
            line-height: 1.56;
          }
        }
      }

      .title {
        font-size: 2.4rem;
        line-height: 3.1rem;
        margin-top: 1rem;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-clamp: 2;
        text-overflow: ellipsis;
        overflow: hidden;
        color: $white;
        @media screen and (max-width: $xs) {
          font-size: 16px;
          line-height: 1.56;
        }
      }

      .price {
        font-size: 1.8rem;
        line-height: 2.8rem;
        color: $white;
        @media screen and (max-width: $xs) {
          font-size: 16px;
          line-height: 1.56;
        }
      }

      .author {
        margin-top: 0.5rem;
        font-size: 1.4rem;
        line-height: 2.5rem;
        text-transform: uppercase;
        word-spacing: 3px;
        color: $white;
        @media screen and (max-width: $xs) {
          margin-top: 1rem;
          font-size: 1.6rem;
          line-height: 2.8rem;
        }
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 6rem;
  }
}
</style>

